import {
  BellIcon,
  CheckCircleIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Text,
  Card,
  CardHeader,
  Flex,
  Avatar,
  Heading,
  IconButton,
  HStack,
  Grid,
  GridItem,
  VStack,
  Input,
  InputLeftElement,
  InputGroup,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Tenant } from "../../Models/Tenant";
import { useMsal } from "@azure/msal-react";
import { Assignment } from "../../Models/Assignment";
import noImage from "../Global/noImage.png";
import {
  getAllActions,
  getAllMembers,
  listAllBlobs,
} from "../../Services/CommandCenterService";
import { Blob } from "../../Models/blob";
import { Action } from "../../Models/Action";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";

interface IDashboardTable {
  tenants: Array<Tenant>;
  assignments: Array<Assignment>;
}

const TenantTable: React.FC<IDashboardTable> = ({ tenants, assignments }) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const objectId = activeAccount?.idTokenClaims?.oid;
  const MaxDisplayNameLength = 20;

  const [searchTerm, setSearchTerm] = useState("");
  const [isToggled, setIsToggled] = useState(
    sessionStorage.getItem("isToggled") === "false"
  );
  const [images, setImages] = useState<Blob[]>([]);
  const [actionsAlerts, setActionsAlerts] = useState<Action[]>([]);
  const [members, setMembers] = useState<{ id: string; displayName: string }[]>(
    []
  );

  const CircleIcon = ({ icon, tooltip, ...props }) => (
    <Icon viewBox="50 0 300 250" {...props}>
      <Tooltip label={tooltip} aria-label="A tooltip">
        <Icon as={icon} />
      </Tooltip>
      {/* Small red notification circle */}
      <circle cx="230" cy="30" r="55" fill="#F56565" />
    </Icon>
  );

  const handleToggle = () => {
    const newToggleState = !isToggled;
    setIsToggled(newToggleState);
    sessionStorage.setItem("isToggled", newToggleState.toString());
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const sortedTenants = tenants
    .filter((tenant) => {
      if (isToggled) {
        return true;
      } else {
        return assignments.some(
          (assignment) =>
            assignment.userId === objectId &&
            assignment.azureTenantId === tenant.azureTenantId
        );
      }
    })
    .filter((tenant) =>
      tenant.azureTenantName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.azureTenantName.localeCompare(b.azureTenantName));

  useEffect(() => {
    const storedToggleState = sessionStorage.getItem("isToggled");
    if (storedToggleState) {
      setIsToggled(storedToggleState === "true");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const images = await listAllBlobs();
      const actionsss = await getAllActions();
      setActionsAlerts(actionsss);
      setImages(images);

      //Get members from group
      try {
        const groupId = "cc08e2b1-8db3-45f5-8b86-bbc563a1883b"; // pt-cloudcontrol-keyvaults
        const groupMembers = await getAllMembers(groupId);
        // Filter members who do not have an existing assignment for the current tenant
        setMembers(groupMembers);
      } catch (error) {
        console.error("Failed to retrieve group members:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HStack align="center" mb={4}>
        <InputGroup maxW={"20%"}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.500" />
          </InputLeftElement>
          <Input
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            bgColor={"white"}
            borderRadius={50}
            boxShadow={"sm"}
            _hover={{
              bg: "transparent",
              color: "transpartent",
            }}
          />
        </InputGroup>

        <HStack spacing={0}>
          <IconButton
            aria-label="Toggle view"
            icon={isToggled ? <ViewIcon /> : <ViewOffIcon />}
            onClick={handleToggle}
            _hover={{ bg: "transparent" }}
          />
          <Text fontSize={"sm"} textColor={"gray.400"}>
            {isToggled ? "Showing All" : "Showing Mine"}
          </Text>
        </HStack>
      </HStack>
      <Grid
        templateColumns={{
          base: "repeat(auto-fill, minmax(250px, 1fr))", // On smaller screens
          sm: "repeat(1, 1fr)", // Above 480px
          smm: "repeat(2, 1fr)", // Above 624px
          md: "repeat(2, 1fr)", // Above 768px
          mdd: "repeat(2, 1fr)", // Above 880px
          lg: "repeat(3, 1fr)", // Above 992px
          lgg: "repeat(4, 1fr)", // Above 1350px
          xxl: "repeat(5, 1fr)", // Above 1600px
        }}
        gap={4}
      >
        {sortedTenants.map((tenant) => {
          const isHandledByKnownUser = (handledBy: string | undefined) =>
            handledBy !== undefined &&
            handledBy !== "" &&
            handledBy !== null &&
            members.some((m) => m.displayName === handledBy);

          const tenantImage = images.find(
            (image) => image.name === tenant.azureTenantId
          );

          const tenantActionsAlerts = actionsAlerts?.filter(
            (action) =>
              action.assistanceRequired === true &&
              action.azureTenantId === tenant.azureTenantId
          );

          const tenantStatusAlerts = actionsAlerts?.filter(
            (action) =>
              action.statusId === 1 &&
              action.azureTenantId === tenant.azureTenantId
          );

          const unknownUserAlerts = actionsAlerts?.filter(
            (action) =>
              action.azureTenantId === tenant.azureTenantId &&
              action.handledBy !== undefined && // Ensure handledBy is not undefined before checking
              action.handledBy !== "" &&
              action.handledBy !== null &&
              !isHandledByKnownUser(action.handledBy)
          );

          const loggingUnknownUser = actionsAlerts?.filter(
            (action) =>
              action.azureTenantId === tenant.azureTenantId &&
              action.handledBy !== undefined && // Ensure handledBy is not undefined before checking
              action.handledBy !== "" &&
              action.handledBy !== null &&
              !isHandledByKnownUser(action.handledBy) &&
              console.log("👥 Unknown User:", action.handledBy)
          );
          const fadeStyles = {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "hidden",
            position: "relative",
            "::after": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              width: "20%",
              height: "100%",
              background: `linear-gradient(to left, white, transparent 50%)`,
            },
          };

          return (
            <GridItem key={tenant.id} w="100%" minW={"xs"}>
              <RouterLink to={`${tenant.id}`}>
                <Card>
                  <CardHeader>
                    <Flex>
                      <Flex flex="2" justifyContent={"center"}>
                        <Avatar
                          name="no image"
                          src={tenantImage?.uri || noImage}
                        />
                      </Flex>
                      <Flex flex="8" textAlign={"left"} justifyContent={"left"}>
                        <VStack alignItems={"left"} ml={8}>
                          <Box>
                            <HStack spacing={2}>
                              <Heading
                                textTransform={"capitalize"}
                                isTruncated={true}
                                fontSize={"lg"}
                                sx={
                                  tenant.azureTenantName.length >
                                  MaxDisplayNameLength
                                    ? fadeStyles
                                    : {}
                                }
                              >
                                {tenant.azureTenantName.length >
                                MaxDisplayNameLength
                                  ? tenant.azureTenantName.substring(
                                      0,
                                      MaxDisplayNameLength
                                    )
                                  : tenant.azureTenantName}
                              </Heading>
                              {tenant.active ? (
                                <CheckCircleIcon
                                  color="green.500"
                                  boxSize={3}
                                />
                              ) : (
                                <WarningTwoIcon color="red.500" boxSize={3} />
                              )}
                            </HStack>
                          </Box>
                          <Box>
                            <HStack spacing={2}>
                              {tenantStatusAlerts.length > 0 && (
                                <CircleIcon
                                  color="gray.700"
                                  boxSize={5}
                                  icon={AssignmentIcon}
                                  tooltip="New Actions"
                                />
                              )}
                              {tenantActionsAlerts.length > 0 && (
                                <CircleIcon
                                  color="gray.700"
                                  boxSize={5}
                                  icon={BellIcon}
                                  tooltip="Assistance Required"
                                />
                              )}
                              {unknownUserAlerts.length > 0 && (
                                <CircleIcon
                                  color="gray.700"
                                  boxSize={5}
                                  icon={NoAccountsIcon}
                                  tooltip="Unknown User"
                                />
                              )}
                              {tenantActionsAlerts.length === 0 &&
                                tenantStatusAlerts.length === 0 &&
                                unknownUserAlerts.length === 0 && (
                                  <Text
                                    color={"gray.400"}
                                    //fontStyle={"italic"}
                                    fontSize={12}
                                  >
                                    No new events
                                  </Text>
                                )}
                              {/* <CircleIcon
                                color="gray.700"
                                boxSize={5}
                                icon={CheckCircleIcon}
                              /> */}
                              {/* <CircleIcon
                                color="gray.700"
                                boxSize={5}
                                icon={ChatIcon}
                              /> */}
                            </HStack>
                          </Box>
                        </VStack>
                      </Flex>
                    </Flex>
                  </CardHeader>
                </Card>
              </RouterLink>
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
};

export default TenantTable;
