import { protectedResources } from "../authConfig";
import { getToken, getToken2 } from "./GraphService";
import { Tenant } from "../Models/Tenant";
import { Action, statusChangeOnAction } from "../Models/Action";
import { TemplateAction } from "../Models/TemplateAction";
import { Category } from "../Models/Category";
import { CustomerInfo } from "../Models/CustomerInfo";
import { WikiPage } from "../Models/WikiPage";
import { SubPage } from "../Models/SubPage";
import { Comment } from "../Models/Comment";
import { Assignment } from "../Models/Assignment";

export const getAllActions = async () => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.actionsEndpoint,
    options
  );
  const data = await result.json();
  return data;
};

export const getAllTemplateActions = async () => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.templateActionsEndpoint,
    options
  );
  const data = await result.json();
  return data;
};

export const getTenantAlertsById = async (azureTenantId: string) => {
  const token = await getToken();
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.alertsEndpoint}/${azureTenantId}`,
    options
  );
  const data = await result.json();
  return data;
};

export const getCustomerInfosByTenantId = async (azureTenantId: string) => {
  const token = await getToken();
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.customerInfoEndpoint}/${azureTenantId}`,
    options
  );
  const data = await result.json();
  return data;
};

export const getAllActionsByTenantId = async (
  id: string,
  includecompleted: boolean = false
) => {
  const token = await getToken();
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.tenantActionsEndpoint}/${id}?includeCompleted=${includecompleted}`,
    options
  );

  const data = await result.json();
  return data;
};

export const getAllCommentsByActionId = async (
  id: number,
  isexternal: boolean = false
) => {
  const token = await getToken();
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.actionCommentsEndpoint}/${id}?isExternal=${isexternal}`,
    options
  );

  const data = await result.json();
  return data;
};

export const getAllComments = async () => {
  const token = await getToken();
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.commentsEndpoint,
    options
  );

  const data = await result.json();
  return data;
};

export const createCommentOnAction = async (comment: Comment) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(comment),
  };

  const result = await fetch(
    protectedResources.apiEndpoints.commentsEndpoint,
    options
  );

  return result;
};

export const deleteCommentById = async (id: number) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "DELETE",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.commentsEndpoint}/${id}`,
    options
  );

  return result;
};

export const getTenantById = async (id: string): Promise<Tenant> => {
  const token = await getToken();
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.tenantEndpoint}/${id}`,
    options
  );
  const data = await result.json();
  return data;
};

export const getAllTenants = async () => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.tenantEndpoint,
    options
  );

  const data = await result.json();
  return data;
};

export const getAllCategories = async () => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.categoriesEndpoint,
    options
  );
  const data = await result.json();
  return data;
};

export const createCategory = async (category: Category) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(category),
  };

  const result = await fetch(
    protectedResources.apiEndpoints.categoriesEndpoint,
    options
  );

  return result;
};

export const updateCategory = async (category: Category) => {
  const { id } = category;
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(category),
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.categoriesEndpoint}/${id}`,
    options
  );

  return result;
};

export const createActionOnTenant = async (action: Action) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(action),
  };

  const result = await fetch(
    protectedResources.apiEndpoints.actionsEndpoint,
    options
  );

  return result;
};

export const updateActionOnTenant = async (action: Action) => {
  const { id } = action;
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(action),
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.actionsEndpoint}/${id}`,
    options
  );

  return result;
};

export const updateCrmCase = async (action: statusChangeOnAction) => {
  console.log({ EnvironmentURL: process.env.REACT_APP_FLOW_URL });

  const url = process.env.REACT_APP_FLOW_URL
    ? process.env.REACT_APP_FLOW_URL
    : "EMPTY??";

  console.log({ url });
  //"https://prod-114.westeurope.logic.azure.com:443/workflows/dbcfb0d6f8604c52aae12415e5533d5a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=uprxA9OckgZqK3J7z0evH62_IMJVa83yERvWcFCIe2U";

  console.log(JSON.stringify(action), "Dette blir sendt til CRM");
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(action),
    headers: {
      "Content-Type": "application/json",
    },
  });
  console.log(response);
  return response.ok;
  // } else {
  //   console.log({ Environment: process.env.NODE_ENV });
  //   const url =
  //     "https://prod-59.westeurope.logic.azure.com:443/workflows/745cf21347fd4ed1bfc72f6ded305f0e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=t1xlsP9Vm1pfrLn1Bj6EbgKyY1hyl61aPSVeV0Qt790";

  //   console.log(JSON.stringify(action), "Dette blir sendt til CRM");
  //   const response = await fetch(url, {
  //     method: "POST",
  //     body: JSON.stringify(action),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });

  //   console.log(response);
  //   return response.ok;
  // }
};

export const deleteActionOnTenant = async (id: number) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "DELETE",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.actionsEndpoint}/${id}`,
    options
  );

  return result;
};

export const updateTenant = async (tenant: Tenant) => {
  const { id } = tenant;

  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(tenant),
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.tenantEndpoint}/${id}`,
    options
  );

  return result;
};

export const createTenant = async (tenant: Tenant) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(tenant),
  };

  const result = await fetch(
    protectedResources.apiEndpoints.tenantEndpoint,
    options
  );

  return result;
};

export const deleteTenantById = async (id: string) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "DELETE",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.tenantEndpoint}/${id}`,
    options
  );

  return result;
};

export const createTemplateAction = async (templateAction: TemplateAction) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(templateAction),
  };

  const result = await fetch(
    protectedResources.apiEndpoints.templateActionsEndpoint,
    options
  );

  return result;
};

export const updateTemplateAction = async (templateAction: TemplateAction) => {
  const { id } = templateAction;
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(templateAction),
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.templateActionsEndpoint}/${id}`,
    options
  );

  return result;
};

export const deleteTemplateAction = async (id: number) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "DELETE",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.templateActionsEndpoint}/${id}`,
    options
  );

  return result;
};

export const createCustomerInfoOnTenant = async (
  customerInfo: CustomerInfo
) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(customerInfo),
  };

  const result = await fetch(
    protectedResources.apiEndpoints.customerInfoEndpoint,
    options
  );

  return result;
};

export const updateCustomerInfoOnTenant = async (
  customerInfo: CustomerInfo
) => {
  const { azureTenantId } = customerInfo;
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(customerInfo),
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.customerInfoEndpoint}/${azureTenantId}`,
    options
  );

  return result;
};

export const getAllStatuses = async () => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.statusEndpoint,
    options
  );
  const data = await result.json();
  return data;
};

export const getAllWikiPages = async () => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.wikiEndpoint,
    options
  );
  const data = await result.json();
  return data;
};

export const createWikiPage = async (wikiPage: WikiPage) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(wikiPage),
  };

  const result = await fetch(
    protectedResources.apiEndpoints.wikiEndpoint,
    options
  );

  return result;
};

export const deleteWikiPageById = async (id: number) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "DELETE",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.wikiEndpoint}/${id}`,
    options
  );

  return result;
};

export const updateWikiPage = async (wikiPage: WikiPage) => {
  const { id } = wikiPage;
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(wikiPage),
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.wikiEndpoint}/${id}`,
    options
  );

  return result;
};

export const getAllSubPages = async () => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.subPageEndpoint,
    options
  );
  const data = await result.json();
  return data;
};

export const createSubPage = async (subPage: SubPage) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(subPage),
  };

  const result = await fetch(
    protectedResources.apiEndpoints.subPageEndpoint,
    options
  );

  return result;
};

export const deleteSubPageById = async (id: number) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "DELETE",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.subPageEndpoint}/${id}`,
    options
  );

  return result;
};

export const updateSubPage = async (subPage: SubPage) => {
  const { id } = subPage;
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(subPage),
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.subPageEndpoint}/${id}`,
    options
  );

  return result;
};

export const listAllBlobs = async () => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.imageEndpoint,
    options
  );
  const data = await result.json();
  return data;
};

export const uploadFile = async (file: File, filename: string) => {
  const token = await getToken();

  const formData = new FormData();
  formData.append("file", file, filename);

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "POST",
    headers: headers,
    body: formData,
  };

  try {
    const result = await fetch(
      protectedResources.apiEndpoints.imageEndpoint,
      options
    );
    const data = await result.json();
    return data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("File upload failed");
  }
};

export const deleteFile = async (filename: string) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "DELETE",
    headers: headers,
  };

  try {
    const result = await fetch(
      `${protectedResources.apiEndpoints.imageEndpoint}/${filename}`,
      options
    );
    const data = await result.json();
    return data;
  } catch (error) {
    console.error("Error deleting file:", error);
    throw new Error("File deletion failed");
  }
};

export const getAllAssignments = async () => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    protectedResources.apiEndpoints.assignmentEndpoint,
    options
  );

  const data = await result.json();
  return data;
};

export const getAllAssignmentsByTenantId = async (id: string) => {
  const token = await getToken();
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.assignmentEndpoint}/${id}`,
    options
  );

  const data = await result.json();
  return data;
};

export const createAssignment = async (assignment: Assignment) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(assignment),
  };

  const result = await fetch(
    protectedResources.apiEndpoints.assignmentEndpoint,
    options
  );

  return result;
};

export const deleteAssignmentById = async (id: number) => {
  const token = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.set("Content-Type", "application/json");

  const options = {
    method: "DELETE",
    headers: headers,
  };

  const result = await fetch(
    `${protectedResources.apiEndpoints.assignmentEndpoint}/${id}`,
    options
  );

  return result;
};

//gets all members in a group and their user info
export const getAllMembers = async (groupId) => {
  const token = await getToken2();

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  const result = await fetch(
    `https://graph.microsoft.com/v1.0/groups/${groupId}/members`,
    options
  );

  if (!result.ok) {
    throw new Error("Failed to fetch group members!!!");
  }

  const data = await result.json();
  return data.value;
};
