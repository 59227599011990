import { useMsal } from "@azure/msal-react";
import { Avatar, Button, Flex, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import SignOutButton from "./SignOutButton";

const NavBar = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [userPhoto, setUserPhoto] = useState<string | undefined>();

  useEffect(() => {
    const fetchPhoto = async () => {
      // Ensure you handle multiple accounts appropriately
      if (!activeAccount) return;

      // Request to get access token
      const accessTokenResponse = await instance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: activeAccount,
      });

      // Use token to fetch photo from Microsoft Graph API
      const response = await fetch(
        "https://graph.microsoft.com/v1.0/me/photo/$value",
        {
          headers: {
            Authorization: `Bearer ${accessTokenResponse.accessToken}`,
          },
        }
      );

      if (response.ok) {
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setUserPhoto(imageUrl);
      }
    };
    fetchPhoto();
  }, [instance]);

  return (
    <Flex backgroundColor="white" mb={8} w="full">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        pt={2}
        pb={2}
        maxW="1900"
        margin="0 auto"
        w="full"
        px={8}
      >
        <Flex>
          <Link mr={4}>
            <RouterLink to="/tenants">
              <Text fontSize="md">Tenants</Text>
            </RouterLink>
          </Link>
          <Link mr={4}>
            <RouterLink to="/configuration">
              <Text fontSize="md">Configuration</Text>
            </RouterLink>
          </Link>
          <Link mr={4}>
            <RouterLink to="/wiki">
              <Text fontSize="md">Wiki</Text>
            </RouterLink>
          </Link>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Avatar size="sm" src={userPhoto} />
          <Button
            variant="ghost"
            mr={2}
            bg="transparent"
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ boxShadow: "none" }}
          >
            {activeAccount?.name}
          </Button>
          <SignOutButton />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NavBar;
